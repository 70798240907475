<template>
  <div id="statisctics-page">
    <p style="font-weight: 500; font-size: 1.1rem" class="text-primary">
      Sorgu saati: {{ datetimeToTimeText(dateTime) }}
    </p>
    <b-row>
      <b-col md="6">
        <b-card title="Yeni Üyeler" style="min-height: 195px">
          <div v-if="!usersInfo" style="text-align: center; margin-top: 80px">
            Yükleniyor...
          </div>
          <div
            v-else
            v-html="usersInfo"
            style="margin-top: 20px; margin-left: 2%; font-size: 1.15rem"
          ></div>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Aktiviteler" style="min-height: 195px">
          <div
            v-if="!activityInfo"
            style="text-align: center; margin-top: 80px"
          >
            Yükleniyor...
          </div>
          <div
            v-else
            v-html="activityInfo"
            style="margin-top: 20px; margin-left: 2%; font-size: 1.15rem"
          ></div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="6">
        <b-card title="Kronometre" style="min-height: 300px">
          <div
            v-if="!stopwatchInfo"
            style="text-align: center; margin-top: 80px"
          >
            Yükleniyor...
          </div>
          <div
            v-else
            v-html="stopwatchInfo"
            style="margin-top: 20px; margin-left: 2%; font-size: 1.15rem"
          ></div>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Deneme Sınavları" style="min-height: 300px">
          <div
            v-if="!trialExamInfo"
            style="text-align: center; margin-top: 80px"
          >
            Yükleniyor...
          </div>
          <div
            v-else
            v-html="trialExamInfo"
            style="margin-top: 20px; margin-left: 2%; font-size: 1.15rem"
          ></div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="4">
        <b-card title="TUS Simülasyonu" style="min-height: 400px">
          <div
            v-if="!simulationInfo"
            style="text-align: center; margin-top: 80px"
          >
            Yükleniyor...
          </div>
          <div
            v-else
            v-html="simulationInfo"
            style="margin-top: 20px; margin-left: 2%; font-size: 1.15rem"
          ></div>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card title="Sunucu Yükü (Son 15 dk)" style="min-height: 400px">
          <div
            v-if="!serverLoadInfo"
            style="text-align: center; margin-top: 80px"
          >
            Yükleniyor...
          </div>
          <div
            v-else
            v-html="serverLoadInfo"
            style="margin-top: 20px; margin-left: 2%; font-size: 1.15rem"
          ></div>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card title="Versiyon Dağılımı (Son 24 Saat)" style="min-height: 400px">
          <div
            v-if="!appVersionInfo"
            style="text-align: center; margin-top: 80px"
          >
            Yükleniyor...
          </div>
          <div
            v-else
            v-html="appVersionInfo"
            style="margin-top: 20px; margin-left: 2%; font-size: 1.15rem"
          ></div>
        </b-card>
      </b-col>      
    </b-row>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BImg,
  BLink,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
  BTable,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BCardTitle,
  BInputGroupPrepend,
  BAlert,
  BFormTextarea,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

/* eslint-disable global-require */
export default {
  components: {
    ToastificationContent,
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BLink,
    BCard,
    BBadge,
    BImg,
    BTable,
    AppCollapseItem,
    AppCollapse,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BCardTitle,
    BInputGroupPrepend,
    BAlert,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dateTime: new Date(),
      usersInfo: null,
      stopwatchInfo: null,
      activityInfo: null,
      trialExamInfo: null,
      serverLoadInfo: null,
      simulationInfo: null,
      appVersionInfo: null,
    };
  },

  watch: {},

  computed: {},
  methods: {
    datetimeToTimeText(dateTime) {
      var hour = dateTime.getHours();
      var minute = dateTime.getMinutes();
      var seconds = dateTime.getSeconds();
      return (
        (hour < 10 ? "0" + hour : hour) +
        ":" +
        (minute < 10 ? "0" + minute : minute) +
        ":" +
        (seconds < 10 ? "0" + seconds : seconds)
      );
    },
  },
  created() {
    let moduleId = "app_statistics"; 
    let moduleName = "TUSBuddy İstatistikleri"; 
    if(JSON.parse(localStorage.getItem("adminObject"))[moduleId] != 1){
      this.$router.push("/erisim-kapali?moduleName="+moduleName);
      return; 
    } 

    var self = this;

    store
      .dispatch("appStatistics/getStatistics", "getServerLoadStatistics")
      .then((response) => {
        self.serverLoadInfo = response;
      });

    store
      .dispatch("appStatistics/getStatistics", "getAppVersionStatistics")
      .then((response) => {
        self.appVersionInfo = response;
      });

    store
      .dispatch("appStatistics/getStatistics", "getUserStatistics")
      .then((response) => {
        self.usersInfo = response;
      });

    store
      .dispatch("appStatistics/getStatistics", "getActivityStatistics")
      .then((response) => {
        self.activityInfo = response;
      });

    store
      .dispatch("appStatistics/getStatistics", "getStopwatchStatistics")
      .then((response) => {
        self.stopwatchInfo = response;
      });

    store
      .dispatch("appStatistics/getStatistics", "getTrialExamStatistics")
      .then((response) => {
        self.trialExamInfo = response;
      });

    store
      .dispatch("appStatistics/getStatistics", "getSimulationStatistics")
      .then((response) => {
        self.simulationInfo = response;
      });
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";

p {
  line-height: 1.3rem !important;
}

#statisctics-page .card-body{
  padding: 1rem 1.2rem 0.6rem 1.2rem;
}



</style>
